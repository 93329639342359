import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "yeast-storage-and-maintenance"
    }}>{`Yeast Storage And Maintenance`}</h1>
    <p>{`by Chris White at `}<a parentName="p" {...{
        "href": "https://www.whitelabs.com/beer/yeast-storage-and-maintenance-0"
      }}>{`https://www.whitelabs.com/beer/yeast-storage-and-maintenance-0`}</a></p>
    <p>{`Most brewers agree that the best place to store yeast is under beer. But two crucial factors are temperature and time.`}</p>
    <ul>
      <li parentName="ul">{`The ideal storage temperatures range is between 33-38oF.  Cold temperatures will also help retard bacterial growth.`}</li>
      <li parentName="ul">{`As yeast sits in storage, they consume their glycogen reserves.  Glycogen deprivation weakens their cell walls and makes them more susceptible to rupture. Cold temperatures retard this process.`}</li>
    </ul>
    <p>{`Test yeast before use:
The best thing to do for yeast after it has been stored for two weeks – if it tests clean -- is to add some fresh wort before using.  This helps to restore yeast strength and ensures a successful fermentation.  Simply pour off beer that has separated from flocculated yeast, add fresh wort at 9-12 Plato, and let it sit at room temperature for 10-20 hours. Assuming yeast activity was evident in this "starter" or "activator", pitch into fresh wort as usual.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      